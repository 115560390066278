import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a className="sel" href="/pytania-i-odpowiedzi/">
                    Pytania i odpowiedzi
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/pytania-i-odpowiedzi/" className="sel">
              {" "}
              &gt; Pytania i odpowiedzi
            </a>
          </div>
          <div id="page_content">
            <div className="col2">
              <h1>Pytania i odpowiedzi</h1>
              <div className="row">
                <p className="title">
                  Jak długo można stosować tabletki Sudafed<sup>®</sup>?
                </p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify",
                      }}
                    >
                      <span className="s1">
                        Stosowanie{" "}
                        <a href="/produkty/sudafed_tabletki/" target="_blank">
                          tabletek Sudafed
                        </a>
                      </span>
                      <span className="s2">
                        <b>
                          <sup>
                            <a
                              href="/produkty/sudafed_tabletki/"
                              target="_blank"
                            >
                              ®
                            </a>
                            &nbsp;
                          </sup>
                        </b>
                      </span>
                      <span className="s1">
                        jest uzależnione od wieku. Zazwyczaj objawy{" "}
                        <a href="/slowniczek/#przeziebienie" target="_blank">
                          przeziębienia
                        </a>
                        , takie jak katar oraz blokada nosa powinny ustąpić w
                        ciągu 7 dni. Jeśli w tym czasie u osób dorosłych nie
                        nastąpiła poprawa lub pojawiły się inne objawy (np.
                        gorączka, ciągły ból głowy), należy skontaktować się z
                        lekarzem. W przypadku dzieci powyżej 12 roku życia można
                        je podawać maksymalnie przez cztery dni.&nbsp;
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row">
                <p className="title">
                  Jaka jest maksymalna dobowa dawka pseudofedryny?
                </p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify",
                      }}
                    >
                      <span className="s1">
                        Dobowa dawka{" "}
                        <a href="/slowniczek/#pseudoefedryna" target="_blank">
                          pseudoefedryny
                        </a>
                        , którą można przyjąć to 240 mg<sup>1</sup>. Jedna{" "}
                        <a href="/produkty/sudafed_tabletki/" target="_blank">
                          tabletka Sudafed
                        </a>
                      </span>
                      <a href="/produkty/sudafed_tabletki/" target="_blank">
                        <span className="s2">
                          <b>
                            <sup>®</sup>
                          </b>
                        </span>
                      </a>
                      <span className="s1">
                        {" "}
                        zawiera 60 mg pseudoefedryny, dlatego w ciągu doby można
                        przyjąć maksymalnie 4 tabletki. &nbsp;&nbsp;
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row">
                <p className="title">
                  Jak się leczyć mądrze - kiedy stosować leki złożone, a kiedy
                  jednoskładnikowe?
                </p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify",
                      }}
                    >
                      <span className="s1">
                        Leczenie zawsze powinno być dostosowane do konkretnej
                        potrzeby. Decyzję o wyborze preparatu należy podejmować
                        świadomie, mając na uwadze przede wszystkim cel
                        leczenia. W przypadku pojedynczych objawów zaleca się
                        stosowanie leków jednoskładnikowych. Nie ma wtedy
                        potrzeby stosowania innych leków lub leków złożonych
                        działających również na inne objawy. Pamiętajmy, że leki
                        o różnych nazwach handlowych mogą zawierać tą samą
                        substancję czynną. Stosowanie leków o różnych nazwach,
                        zawierających te same substancje czynne
                      </span>
                      <span className="s2"> </span>
                      <span className="s1">
                        może prowadzić do przedawkowania.&nbsp;
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row">
                <p className="title">
                  {" "}
                  Jak długo można stosować aerozole do nosa Sudafed<sup>®</sup>?
                </p>
                <div className="desc">
                  <div className="wrapper">
                    <div>
                      <p
                        className="p1"
                        style={{
                          "-webkit-text-align": "justify",
                          "text-align": "justify",
                        }}
                      >
                        <span className="s1">
                          W aerozolach do nosa{" "}
                          <a
                            href="/produkty/sudafed_xylospray/"
                            target="_blank"
                          >
                            Sudafed
                          </a>
                        </span>
                        <span className="s2">
                          <b>
                            <sup>
                              <a
                                href="/produkty/sudafed_xylospray/"
                                target="_blank"
                              >
                                ®
                              </a>
                              *
                            </sup>
                          </b>
                        </span>
                        <span className="s1">
                          {" "}
                          substancją czynną jest chlorowodorek{" "}
                          <a
                            href="/slowniczek/#ksylometazolina"
                            target="_blank"
                          >
                            ksylometazoliny
                          </a>
                          . Aerozoli nie należy ich stosować dłużej niż przez 7
                          dni, chyba że lekarz zaleci inaczej. Powtórne
                          stosowanie można rozpocząć po kilkudniowej przerwie.
                        </span>
                      </p>
                      <p
                        className="p2"
                        style={{
                          "-webkit-text-align": "justify",
                          "text-align": "justify",
                        }}
                      >
                        &nbsp;
                      </p>
                      <p
                        className="p1"
                        style={{
                          "-webkit-text-align": "justify",
                          "text-align": "justify",
                        }}
                      >
                        <span className="s1">* Sudafed</span>
                        <span className="s2">
                          <sup>®</sup>
                        </span>
                        <span className="s1"> XyloSpray HA, Sudafed</span>
                        <span className="s2">
                          <sup>® </sup>
                        </span>
                        <span className="s1">XyloSpray, Sudafed</span>
                        <span className="s2">
                          <sup>®</sup>
                        </span>
                        <span className="s1"> XyloSpray dla dzieci</span>
                        <span className="s1">, Sudafed</span>
                        <span className="s2">
                          <sup>®</sup>
                        </span>
                        <span className="s1">
                          &nbsp;XyloSpray HA dla dzieci
                        </span>
                      </p>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row">
                <p className="title">
                  Na czym polega specjalna technologia wykorzystana przy
                  produkcji aerozolu do nosa{" "}
                  <span className="nowrap">
                    Sudafed<sup>®</sup> Xylospray HA?
                  </span>
                </p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify",
                      }}
                    >
                      <span className="s1">
                        Opatentowana budowa pompki składającej się ze srebrnej
                        spirali oraz systemu filtrów 3K zapewnia sterylność
                        produktu, dzięki czemu{" "}
                        <a
                          href="/produkty/sudafed_xylospray_ha/"
                          target="_blank"
                        >
                          Sudafed
                        </a>
                      </span>
                      <a href="/produkty/sudafed_xylospray_ha/" target="_blank">
                        <span className="s2">
                          <sup>® </sup>
                        </span>
                      </a>
                      <span className="s1">
                        <a
                          href="/produkty/sudafed_xylospray_ha/"
                          target="_blank"
                        >
                          XyloSpray HA
                        </a>{" "}
                        nie zawiera konserwantów i może być stosowany do 12
                        miesięcy po otwarciu. &nbsp;
                      </span>
                    </p>
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify",
                      }}
                    >
                      &nbsp;
                    </p>
                    <video controls width="100%">
                      <source
                        src="/kcfinder/uploads/files/film/Ursatec.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row">
                <p className="title">Jakie są właściwości dekspantenolu?</p>
                <div className="desc">
                  <div className="wrapper">
                    <p>
                      Dekspantenol jest prowitaminą B51, która przyspiesza
                      proces gojenia podrażnionej lub uszkodzonej śluzówki nosa
                      <sup>1</sup>, chroni błonę śluzową<sup>1</sup> oraz
                      przyspiesza powrót do zdrowia po operacjach nosa i zatok
                      <sup>2</sup>.
                    </p>
                    <p>&nbsp;</p>
                    <div>
                      <span
                        style={{
                          "font-size": "11px",
                        }}
                      >
                        1. Ebner F, Heller A, Rippke F, Tausch I. Topical use of
                        dexpanthenol in skin disorders. Am J Clin Dermatol.
                        2002;3(6):427-33.
                      </span>
                    </div>
                    <div>
                      <span
                        style={{
                          "font-size": "11px",
                        }}
                      >
                        2. Gouteva I, Shah-Hosseini K, Meiser P. Clinical
                        efficacy of a spray containing hyaluronic Acid and
                        dexpanthenol after surgery in the nasal cavity
                      </span>
                    </div>
                    <div>
                      <span
                        style={{
                          "font-size": "11px",
                        }}
                      >
                        (septoplasty, simple ethmoid sinus surgery, and
                        turbinate surgery). J Allergy (Cairo). 2014;2014:635490.
                      </span>
                    </div>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row">
                <p className="title">
                  Czym różnią się Sudafed Xylospray HA i Sudafed Xylospray DEX?
                </p>
                <div className="desc">
                  <div className="wrapper">
                    <div>&nbsp;</div>
                    <div>
                      Każdy aerozol marki Sudafed odblokowuje nos i zmniejsza
                      ilość wydzieliny.&nbsp;
                    </div>
                    <ul>
                      <li>
                        Sudafed Xylospray HA dodatkowo nawilża śluzówkę nosa.
                        Może być&nbsp; także stosowany podczas alergicznego
                        zapaleniu błony śluzowej nosa. Ułatwia odpływ wydzieliny
                        w zapaleniu zatok przynosowych oraz w zapaleniu trąbki
                        słuchowej ucha środkowego połączonego z
                        przeziębieniem.&nbsp;
                      </li>
                      <li>
                        Sudafed Xylospray DEX działa ochronnie i przyspiesza
                        gojenie się ran na powierzchni błony śluzowej nosa.
                        Warto go stosować w leczeniu niedrożności przewodów
                        nosowych po przebytej operacji nosa.&nbsp;
                      </li>
                    </ul>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row">
                <p className="title">
                  Czy Sudafed Xylospray HA i DEX zawierają konserwanty?{" "}
                </p>
                <div className="desc">
                  <div className="wrapper">
                    <div>&nbsp;</div>
                    <div>
                      Nie, nie zawierają, ponieważ takie rozwiązanie umożliwia
                      specjalna konstrukcja pompki. Konserwanty mogą powodować
                      niekorzystne zmiany na śluzówce nosa, takie jak: możliwe
                      podrażnienia i uszkodzenia błony śluzowej nosa oraz
                      możliwość nasilenia alergicznego nieżytu nosa.&nbsp;
                    </div>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="wysiwyg">
                <p>
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    <sup>1</sup>Sylwia Stachura, Pseudoefedryna [online],
                    [dostęp: 27 kwiecień 2016], Dostępny w Internecie:{" "}
                    <a href="https://portal.abczdrowie.pl/pseudoefedryna">
                      https://portal.abczdrowie.pl/pseudoefedryna
                    </a>
                  </span>
                </p>
              </div>
            </div>
            <div className="col1">
              <div className="related">
                <p className="name">Dowiedz się więcej</p>
                <div className="line">
                  <a href="/poradnik/katar/poznaj_rodzaje_kataru">
                    <div className="c1">
                      <span
                        className="photo"
                        style={{
                          backgroundImage:
                            'url("/web/kcfinder/uploads/files/artykuly/shutterstock_388715146.jpg")',
                        }}
                      />
                    </div>
                    <div className="c2">Poznaj rodzaje kataru </div>
                  </a>
                </div>
                <div className="line">
                  <a href="/poradnik/przeziebienie/domowe_sposoby_na_walke_z_przeziebieniem">
                    <div className="c1">
                      <span
                        className="photo"
                        style={{
                          backgroundImage:
                            'url("/web/kcfinder/uploads/files/artykuly/GettyImages-1030072086.jpg")',
                        }}
                      />
                    </div>
                    <div className="c2">
                      Domowe sposoby na walkę z przeziębieniem{" "}
                    </div>
                  </a>
                </div>
              </div>
              <a href="/dobierz-lek/" className="link_box link">
                <img src="/kcfinder/uploads/files/box/zrobtest.png" />
              </a>
              <a href="/reklama-telewizyjna/" className="link_box video">
                <img src="/web/kcfinder/uploads/files/box/SudafedHAthumbnail.png" />
              </a>
              <a href="/pytania-i-odpowiedzi/" className="quiz_btn" />
            </div>
            <div className="clear" />
          </div>
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px",
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a
                    id="ot-sdk-btn"
                    className="ot-sdk-show-settings"
                    rel="nofollow"
                  >
                    {" "}
                    Ustawienia plików Cookie{" "}
                  </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty. 2023
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
